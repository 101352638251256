<template>
  <div>
    <div>修改密码模板配置</div>
    <div class="select-box">
      <el-row>
        <el-col :span="24">
          <div id="div1">
            <span id="top">消息模板</span>
            <span id="dom">内容：</span>
            <el-input
                id="inp"
                v-model="ruleForm.evalContent"
                label-width="800px"
                style="margin-left: 60px"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <div>
        <el-button type="primary" @click="submitForm" id="but">
          保存
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, ref} from "vue";
import { ElMessage } from "element-plus";
import { getEvalContent, UpdateEvalContent } from "../http/api";

export default {
  name: "ServicePhone",

  setup() {
    const ruleFormRef = ref(null);
    let ruleForm = reactive({
      evalContent: "",
      evalType:""
    });
    //声明查询条件
    let option = reactive({
      evalType: 3
    });
    let getEvaluationConfiguration = async (option) => {
        let EvalContent = await getEvalContent(option);
        ruleForm.evalContent = EvalContent[0].evalContent;
        ruleForm.evalType = EvalContent[0].evalType;
    };


    // 初始化时，获取初始化数据
    getEvaluationConfiguration(option);


    const submitForm = async () => {
      if(ruleForm.evalContent == "" || ruleForm.evalContent == null || ruleForm.evalContent == undefined){
        ElMessage.error({
          message: '模板内容不能为空！',
          type: "error",
          offset:130,
          duration:1500
        });
        return false;
      }
      let data= {
        "evalContent":ruleForm.evalContent,
        "evalType":3
      }
        await UpdateEvalContent(data);
        ElMessage.success({
          message: "保存成功！",
          type: "success",
          offset: 130,
          duration: 2000
        });
    };

    return {
      ruleForm,
      submitForm,
      ruleFormRef,
      option,
    };
  }
};
</script>

<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 27%;
    display: flex;
    align-items: center;
    span {
      width: 170px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
#top {
  margin-bottom: 30px;
  padding: 25px;
  display: block;
  width: 100%;
  background: #f0f0f0;
  text-align: center;
  color: black;
  font-weight: bold;
}
#but {
  position: relative;
  top: -20px;
  left: 1000px;
}
#inp {
  display: block;
  color: #f8cd00;
  width: 1000px;
  height: 50px;
}
#div1 {
  position: relative;
  width: 1000px;
  top: 100px;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
#dom {
  position: relative;
  top: 30px;
  font-weight: bold;
}
:-ms-input-placeholder {
  font-weight: bold;
  color: #010101;
}
</style>
